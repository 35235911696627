import { VisitEntityType } from 'entities/entities-types';
import {
  HttpMethod,
  PaginatedReqType,
  PaginatedResType,
  TipsApiResType,
} from 'shared/api';

export const ENDPOINTS_USERS = {
  GET_MANY_USERS: {
    path: 'admin/get_users',
    method: 'post' as HttpMethod,
    req: {} as {
      statuses?: ('active' | 'suspended' | 'blocked')[];
    },
    res: {} as TipsApiResType<
      {
        avatar: string;
        balanceId: number;
        birthdayDate: string;
        createdAt: string;
        currentGoalId: number;
        deletedAt: string;
        email: string;
        firstname: string;
        lastname: string;
        patronymic: string;
        phoneNumber: string;
        role: 'owner' | 'manager' | 'employee' | 'financeManager';
        status: 'active' | 'suspended' | 'blocked';
        updatedAt: string;
        userId: number;
        userRef: string;
      }[]
    >,
  },

  ACTIVATE_USER: {
    path: 'admin/activate_user',
    method: 'patch' as HttpMethod,
    req: {} as {
      userId: number;
    },
    res: {} as TipsApiResType<string>,
  },

  BLOCK_USER: {
    path: 'admin/block_user',
    method: 'patch' as HttpMethod,
    req: {} as {
      userId: number;
    },
    res: {} as TipsApiResType<string>,
  },

  SUSPEND_USER: {
    path: 'admin/suspend_user',
    method: 'patch' as HttpMethod,
    req: {} as {
      userId: number;
    },
    res: {} as TipsApiResType<string>,
  },
};
