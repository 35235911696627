import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_ADMIN } from './endpoints';
import { createEffect, forward } from 'effector';

export const adminSignInFx = createApiEffect<
  typeof ENDPOINTS_ADMIN.SIGN_IN.req,
  typeof ENDPOINTS_ADMIN.SIGN_IN.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_ADMIN.SIGN_IN,
});

export const adminGetOneOwnFx = createApiEffect<
  typeof ENDPOINTS_ADMIN.ADMIN_GET_ONE_OWN.req,
  typeof ENDPOINTS_ADMIN.ADMIN_GET_ONE_OWN.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_ADMIN.ADMIN_GET_ONE_OWN,
});

export const adminSignIn2FaFx = createApiEffect<
  typeof ENDPOINTS_ADMIN.SIGN_IN_2FA.req,
  typeof ENDPOINTS_ADMIN.SIGN_IN_2FA.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_ADMIN.SIGN_IN_2FA,
});

export const createModerOneFx = createApiEffect<
  typeof ENDPOINTS_ADMIN.CREATE_MODER_ONE.req,
  typeof ENDPOINTS_ADMIN.CREATE_MODER_ONE.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_ADMIN.CREATE_MODER_ONE,
});

export const changePasswordFx = createApiEffect<
  typeof ENDPOINTS_ADMIN.CHANGE_PASSWORD.req,
  typeof ENDPOINTS_ADMIN.CHANGE_PASSWORD.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_ADMIN.CHANGE_PASSWORD,
});

export const changeDataFx = createApiEffect<
  typeof ENDPOINTS_ADMIN.CHANGE_DATA.req,
  typeof ENDPOINTS_ADMIN.CHANGE_DATA.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_ADMIN.CHANGE_DATA,
});

export const signOutFx = createApiEffect<
  typeof ENDPOINTS_ADMIN.SIGN_OUT.req,
  typeof ENDPOINTS_ADMIN.SIGN_OUT.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_ADMIN.SIGN_OUT,
});

export const showErrorFx = createEffect(() => false);

forward({
  from: adminGetOneOwnFx.failData,
  to: showErrorFx,
});
