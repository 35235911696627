import {
  CloseButton,
  Flex,
  getThemeColor,
  Stack,
  TextInput,
  TextInputProps,
  useMantineTheme,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { ConnectedField } from 'effector-forms';
import { useRef } from 'react';
// import { Condition } from 'shared/ui/display';
// import { MyInputError } from './common';

import { InputAutocompleteType } from './types';

type PropsType = TextInputProps & {
  effectorField?: ConnectedField<string | null>;
  autoComplete?: InputAutocompleteType;
};

export default function MyTextInput(props: PropsType) {
  let { leftSection, error, effectorField, ...propsForInput } = props;
  const { ref, height } = useElementSize();
  const gap = 5;
  const refInput = useRef<HTMLInputElement>(null);
  if (effectorField)
    propsForInput = {
      ...propsForInput,
      value: effectorField.value ?? '',
      onChange: (e) => effectorField?.onChange(e.target.value),
      onBlur: () => effectorField?.onBlur(),
    };

  const resultErrorText = effectorField?.errorText();
  return (
    // <Stack pos='relative' gap={gap}>
    <Flex ref={ref}>
      <TextInput
        styles={{ label: { fontSize: '15px' } }}
        radius={10}
        ref={refInput}
        {...propsForInput}
        placeholder=''
        size='md'
        flex={1}
        error={resultErrorText}
        rightSection={
          effectorField && (
            <CloseButton onClick={() => effectorField?.reset()} />
          )
        }
      />
    </Flex>
    // </Stack>
  );
}
