import { Flex, Stack, Text, Title } from '@mantine/core';
import { useUnit } from 'effector-react';
import { $$getPaymentMetrics } from 'entities/statistic';
import '@mantine/charts/styles.css';
import { BarChart, LineChart } from '@mantine/charts';

import { useEffect } from 'react';

import { CardMetrics } from 'features/statistic';

export const Metrics = () => {
  const [metrics] = useUnit([
    $$getPaymentMetrics.$store.map((s) => s.res?.data),
  ]);

  useEffect(() => {
    $$getPaymentMetrics.argEditEvent();
  }, []);

  const data = [
    {
      date: 'Платежи СБП',
      Оплата: metrics?.percentPayInSBP,
    },
    {
      date: 'Вывод СБП',
      Вывод: metrics?.percentPayOutSBP,
    },
    {
      date: 'Платежи Карта',
      Оплата: metrics?.percentPayInCard,
      // Вывод: metrics?.percentPayOutCard,
    },
    {
      date: 'Вывод Карта',
      Вывод: metrics?.percentPayOutCard,
    },
  ];

  return (
    <Stack w='100%' gap='xl' align='center'>
      <Title order={3} mt='lg'>
        Метрики
      </Title>

      <Flex
        w='100%'
        gap='xl'
        wrap='wrap'
        justify={{ md: 'space-between', base: 'center' }}
      >
        <Stack align='center'>
          <Title order={4}>По СБП</Title>
          <Text fw={600}>Платежи</Text>
          <CardMetrics item={metrics?.SBPMetrics?.payIn} />
          <Text fw={600}>Вывод</Text>
          <CardMetrics item={metrics?.SBPMetrics?.payOut} />
        </Stack>

        <Stack align='center'>
          <Title order={4}>По карте</Title>
          <Text fw={600}>Платежи</Text>
          <CardMetrics item={metrics?.cardMetrics?.payIn} />
          <Text fw={600}>Вывод</Text>
          <CardMetrics item={metrics?.cardMetrics?.payOut} />
        </Stack>

        <Stack align='center'>
          <Title order={4}>Всего</Title>
          <Text fw={600}>Платежи</Text>
          <CardMetrics item={metrics?.totalMetrics?.payIn} />
          <Text fw={600}>Вывод</Text>
          <CardMetrics item={metrics?.totalMetrics?.payOut} />
        </Stack>
      </Flex>

      <Title order={3} mt='lg'>
        Проценты Платежей
      </Title>

      <BarChart
        h={300}
        withLegend
        type='percent'
        withXAxis={false}
        barProps={{ radius: 10 }}
        data={data}
        dataKey='date'
        series={[
          { name: 'Оплата', color: 'pink.4' },
          { name: 'Вывод', color: 'blue.4' },
        ]}
      />
    </Stack>
  );
};
