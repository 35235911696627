import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_USERS } from './endpoints';

export const getManyUsersFx = createApiEffect<
  typeof ENDPOINTS_USERS.GET_MANY_USERS.req,
  typeof ENDPOINTS_USERS.GET_MANY_USERS.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_USERS.GET_MANY_USERS,
});

export const changeStatusBlockUserFx = createApiEffect<
  typeof ENDPOINTS_USERS.BLOCK_USER.req,
  typeof ENDPOINTS_USERS.BLOCK_USER.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_USERS.BLOCK_USER,
});

export const changeStatusActivateUserFx = createApiEffect<
  typeof ENDPOINTS_USERS.ACTIVATE_USER.req,
  typeof ENDPOINTS_USERS.ACTIVATE_USER.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_USERS.ACTIVATE_USER,
});

export const changeStatusSuspendUserFx = createApiEffect<
  typeof ENDPOINTS_USERS.SUSPEND_USER.req,
  typeof ENDPOINTS_USERS.SUSPEND_USER.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_USERS.SUSPEND_USER,
});
