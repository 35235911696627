import { Flex, LoadingOverlay, Paper, Stack, Text, Title } from '@mantine/core';
import { useUnit } from 'effector-react';
import { OrgEntityType, StatusValueType } from 'entities/entities-types';
import { $$getManyOrg } from 'entities/org';
import { groupBy } from 'ramda';
import { useEffect } from 'react';
import { ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { orgStatusColor, orgStatusTranslate } from 'shared/lib';

export const Organizations = () => {
  const navigate = useNavigate();

  const handleButtonClick = (orgId: number) =>
    navigate(`/profile-org/${orgId}`);

  const { 0: orgs, 1: isLoading } = useUnit([
    $$getManyOrg.$store.map((s) => s.res?.data),
    $$getManyOrg.attachedFx.pending,
  ]);

  useEffect(() => {
    $$getManyOrg.argEditEvent({
      currentPage: 1,
      itemsPerPage: 10000,
    });
  }, []);

  // Группируем организации по статусам
  const groupedOrgs = groupBy((org) => org.status, orgs || []);

  const orgStatus: StatusValueType[] = [
    'received',
    'considering',
    'canceled',
    'denied',
    'approved',
    'removed',
  ];

  return (
    <Stack>
      <Stack mt={60}>
        <LoadingOverlay h='100vh' visible={isLoading} />
        <Title mb={60} ta='center' order={3}>
          Все организации
        </Title>
        <Flex
          style={{ overflowY: 'auto' }}
          gap={{ base: 30, lg: 60 }}
          justify={{ base: 'space-between', lg: 'center' }}>
          {orgStatus.map((status) => (
            <Stack key={status}>
              <Title c={orgStatusColor(status)} order={4}>
                {orgStatusTranslate(status)}
              </Title>
              {groupedOrgs[status] ? (
                groupedOrgs[status]?.map((org) => (
                  <Paper
                    w={200}
                    onClick={() => handleButtonClick(org.orgId)}
                    style={{ cursor: 'pointer' }}
                    key={org.orgId}
                    radius={10}
                    shadow='xs'
                    p='md'>
                    <Flex align='center' justify='space-between'>
                      <Stack gap={5}>
                        <Text fw='bold' fz={18}>
                          {org.name}
                        </Text>
                      </Stack>
                      <ChevronRight opacity={0.5} />
                    </Flex>
                  </Paper>
                ))
              ) : (
                <Text c='gray'>Еще нет организаций</Text>
              )}
            </Stack>
          ))}
        </Flex>
      </Stack>
    </Stack>
  );
};
