import {
  Button,
  Flex,
  LoadingOverlay,
  Menu,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { OrgEntityType, StatusValueType } from 'entities/entities-types';
import { $$getManyOrg } from 'entities/org';
import {
  $getManyUsers,
  changeStatusActivateUserFx,
  changeStatusBlockUserFx,
  changeStatusSuspendUserFx,
  getManyUsersFx,
} from 'entities/users';
import { groupBy } from 'ramda';
import { useEffect } from 'react';
import { ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { orgStatusColor, orgStatusTranslate } from 'shared/lib';

export const Users = () => {
  const navigate = useNavigate();

  const handleButtonClick = (orgId: number) =>
    navigate(`/profile-org/${orgId}`);

  const { 0: users, 1: isLoading } = useUnit([
    $getManyUsers,
    getManyUsersFx.pending,
  ]);

  useEffect(() => {
    getManyUsersFx({ statuses: [] });
  }, []);

  const mapStatusUser = {
    blocked: { textRu: 'Заблокировать', textEn: 'blocked' , onclick: changeStatusBlockUserFx },
    active: { textRu: 'Разблокировать', textEn: 'active', onclick: changeStatusActivateUserFx },
    suspended: { textRu: 'Заморозить', textEn: 'suspended', onclick: changeStatusSuspendUserFx },
  };
  const statusesUser: ('active' | 'suspended' | 'blocked')[] = [
    'blocked',
    'active',
    'suspended',
  ];

  return (
    <Stack>
      <Stack mt={60}>
        <LoadingOverlay h='100vh' visible={isLoading} />
        <Title mb={60} ta='center' order={3}>
          Все юзеры
        </Title>
        <Stack align='center'>
          <Stack maw={500} align='center'>
            {users.map((user) => (
              <Paper
                w='100%'
                // maw={500}
                // onClick={() => handleButtonClick(user.userId)}
                style={{ cursor: 'pointer' }}
                key={user.userId}
                radius={10}
                shadow='xs'
                p='md'>
                <Flex align='center' justify='space-between'>
                  <Stack gap={5}>
                    <Text fw='bold' fz={18}>
                      {user.lastname} {user.firstname}
                    </Text>
                    <Flex gap={6}>
                      <Text c='gray'>Статус: {user.status};</Text>
                      <Text c='gray'>ID: {user.userId}</Text>
                    </Flex>
                  </Stack>
                  <Menu>
                    <Menu.Target>
                      <Button>Изменить статус</Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                      {statusesUser.map(
                        (item) =>
                          (item !== mapStatusUser[user.status].textEn &&
                            <Menu.Item
                              onClick={() =>
                                mapStatusUser[item].onclick({
                                  userId: user.userId,
                                })
                              }
                              fw={500}
                              fz={16}
                              key={item}>
                              {mapStatusUser[item].textRu}
                            </Menu.Item>
                          ),
                      )}
                    </Menu.Dropdown>
                  </Menu>
                </Flex>
              </Paper>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
