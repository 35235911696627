import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { appReset } from 'entities/events';
import { ENDPOINTS_ORG } from '../endpoints';

export const $formEditCommission = createForm<
  Omit<typeof ENDPOINTS_ORG.EDIT_ORG_COMMISSION.req, 'orgId'>
>({
  validateOn: ['blur', 'submit'],
  fields: {
    tipsPayInPercentageCommission: {
      init: 0,
      rules: [],
    },
    tipsPayOutScalarCommission: {
      init: 0,
      rules: [],
    },
    tipsPayOutPercentageCommission: {
      init: 0,
      rules: [],
    },
    billPayInPercentageCommission: {
      init: 0,
      rules: [],
    },
    billPayOutPercentageCommission: {
      init: 0,
      rules: [],
    },
  },
});

sample({
  clock: [appReset],
  target: [$formEditCommission.reset],
});
