import React, { useEffect, useState } from 'react';
import { Flex, NavLink, Stack } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { signOutFx } from 'entities/admin/effects';
import { useCookies } from 'react-cookie';
import { $$adminGetOneOwn } from 'entities/admin';
import { useUnit } from 'effector-react';

const data = [
  // { link: '/create-moder', label: 'Добавить модератора' },
  { link: '/my-profile', label: 'Профиль' },
  { link: '/organizations', label: 'Организации' },
  { link: '/statistic', label: 'Статистика' },
  { link: '/users', label: 'Юзеры' },
];

export function Navbar() {
  const [, , removeCookie] = useCookies(['authorized']);
  const navigate = useNavigate();
  const handleLogout = () => {
    signOutFx();
    removeCookie('authorized', { path: '/' });
    navigate('/login');
  };

  const { 0: dataAdmin } = useUnit([
    $$adminGetOneOwn.$store.map((s) => s.res?.data),
  ]);

  return (
    <Flex bg='blue.1' align='center' p='sm' justify='space-between'>
      <Flex justify='space-between'>
        {data.map((item) => (
          <Stack key={item.link}>
            <NavLink
              color='blue'
              variant='subtle'
              active
              component={Link}
              label={item.label}
              to={item.link}
            />
          </Stack>
        ))}
        {dataAdmin?.role === 'admin' && (
          <Flex>
            <Stack>
              <NavLink
                color='blue'
                variant='subtle'
                active
                component={Link}
                label='Добавить модератора'
                to='/create-moder'
              />
            </Stack>
            {/* <Stack>
              <NavLink
                color='blue'
                variant='subtle'
                active
                label='Статистика'
                href='/statistic'
              />
            </Stack> */}
          </Flex>
        )}
      </Flex>

      <Stack>
        <NavLink
          color='blue'
          variant='subtle'
          active
          label='Выйти из аккаунта'
          onClick={handleLogout}
        />
      </Stack>
    </Flex>
  );
}
