import { createStore, sample } from 'effector';
import * as ef from './effects';
import { appReset } from 'entities/events';
import { TipsApiResType } from 'shared/api';

export const $getManyUsers = createStore<
  {
    avatar: string;
    balanceId: number;
    birthdayDate: string;
    createdAt: string;
    currentGoalId: number;
    deletedAt: string;
    email: string;
    firstname: string;
    lastname: string;
    patronymic: string;
    phoneNumber: string;
    role: 'owner' | 'manager' | 'employee' | 'financeManager';
    status: 'active' | 'suspended' | 'blocked';
    updatedAt: string;
    userId: number;
    userRef: string;
  }[]
>([])
  .on(ef.getManyUsersFx.doneData, (state, payload) => {
    return payload.data;
  })
  .reset(appReset);


sample({
  clock: [
    ef.changeStatusActivateUserFx.done,
    ef.changeStatusBlockUserFx.done,
    ef.changeStatusSuspendUserFx.done,
  ],
  fn: () => {
    return {
      statuses: [],
    };
  },
  target: ef.getManyUsersFx,
});
