import {
  ActionIcon,
  Flex,
  getThemeColor,
  Stack,
  TextInput,
  TextInputProps,
  useMantineTheme,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { ConnectedField } from 'effector-forms';
import { useRef, useState } from 'react';
import { EyeOff as EyeOffIcon, Eye as EyeOnIcon } from 'react-feather';
import { InputAutocompleteType } from './types';

type PropsType = TextInputProps & {
  effectorField?: ConnectedField<string>;
  autoComplete?: InputAutocompleteType;
};
export default function MyPassInput(props: PropsType) {
  let { leftSection, error, effectorField, ...propsForInput } = props;
  const { ref, height } = useElementSize();
  const gap = 5;
  const refInput = useRef<HTMLInputElement>(null);
  if (effectorField)
    propsForInput = {
      ...propsForInput,
      value: effectorField.value ?? '',
      onChange: (e) => effectorField?.onChange(e.target.value),
      onBlur: () => effectorField?.onBlur(),
    };

  const [isHidden, setIsHidden] = useState(true);

  const resultErrorText = effectorField?.errorText();
  return (
    <Flex ref={ref}>
      <TextInput
        styles={{ label: { fontSize: '15px' } }}
        radius={10}
        ref={refInput}
        size='md'
        {...propsForInput}
        placeholder=''
        type={isHidden ? 'password' : 'text'}
        flex={1}
        error={resultErrorText}
        rightSection={
          <>
            {isHidden ? (
              <ActionIcon
                color='gray'
                variant='subtle'
                onClick={() => setIsHidden(false)}
              >
                <EyeOffIcon size='80%' />
              </ActionIcon>
            ) : (
              <ActionIcon
                color='gray'
                variant='subtle'
                onClick={() => setIsHidden(false)}
              >
                <EyeOffIcon size='80%' />
              </ActionIcon>
            )}
          </>
        }
      />
    </Flex>
  );
}
